section.contact-form {
    min-height: 736px;
    padding: 85px 0;

    .row-mod {
        max-width: 1675px;
        margin: 0 auto;
    }
    .mw {
        max-width: 500px;
        margin-right: auto;
        margin-left: 175px;
    }

    h6 {
        font-size: 16px;
        font-weight: 700;
    }

    .mainImg {
        min-height: 800px;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.02em;
    }
    .mod1 {
        min-height: 736px;
    }
    ::placeholder {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: #26282B;
        text-transform: uppercase;
    }
    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.1em;
        color: #26282B;
        border: none;
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }
    textarea {
        min-height: 142px;
    }
    [id*="btnSend"] {
        max-width: 196px;
        padding: 13px 40px;
    }
    .qc-form-wrapper {
        min-height: 736px;

        @include media-breakpoint-up(xxl) {
            & {
                padding: 115px 15px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                padding: 115px 15px 0;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                padding: 0 15px 0;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 0 15px 0;
            }
        }
    }
}