
section.services-section {
    .row-mod {
        max-width: 1985px;
        margin: 0 auto;
    }
    h6 {
        font-size: 16px;
        font-weight: 700;
    }
    img {
        border-radius: 15px;
    }
    .h4 {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.02em;
    }
    [class*="btn"]:not(.no-fx):not([class*="owl"]), .hover-fx:not([class*="owl"]) {
        border-radius: 15px;
    }
    a:hover {
        // box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);

        .h4 {
            color: $secondary;
        }
        [class*="btn"]:not(.no-fx):not([class*="owl"]), .hover-fx:not([class*="owl"]) {
            transform: translate3d(0, -2px, 0);
            box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
        }
    }
}