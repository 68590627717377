section.testimonials-section {
    padding: 125px 0;
    border-top: 9px solid $primary;
    border-bottom: 9px solid #26282B;

    .row-mod {
        max-width: 1675px;
        margin: 0 auto;
    }
    
    .mw {
        max-width: 650px;
        margin-left: auto;
        margin-right: 175px;
    }

    h6 {
        font-size: 16px;
        font-weight: 700;
    }

    .mainImg {
        min-height: 800px;
    }

    &.testimonials {
        blockquote {
            margin: 0 0 2rem;

            p {
                margin-bottom: 50px;

                @include media-breakpoint-up(xxl) {
                    & {
                        font-size: 27px;
                        line-height: 39px;
                    }
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 27px;
                    line-height: 39px;
                }
            }

            // a {
            //     color: #fff;

            //     &:hover {
            //         color: #d9d9d9;
            //     }
            // }

            cite {
                span {
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.02em !important;

                    &::before {
                        content: '-';
                        position: relative;
                        margin-right: 5px;
                    }
                }

                :nth-child(2) {
                    display: none !important;
                }
                :nth-child(3) {
                    display: none !important;
                }
            }
        }

        .testimonial-wrapper {
            border-right: none !important;

            @media screen and (max-width: 991px) {
                & {
                    margin-bottom: 0px !important;
                    margin-top: 0 !important;
                }
            }
            @include media-breakpoint-up(xxl) {
                & {
                    padding: 20px 15px 30px;
                }
            }
            @media screen and (max-width: 1720px) {
                & {
                    padding: 20px 15px 30px;
                }

            }
            @include media-breakpoint-down(xl) {
                & {
                    padding: 20px 15px 30px;
                }
            }
            @include media-breakpoint-down(sm) {
                & {
                    padding: 20px 15px 30px;
                }
            }

        }
    }
}